import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Container,
    Divider,
    Grid, GridItem,
    Heading, IconButton,
    Image,
    SimpleGrid,
    Stack,
    StackDivider, Table, TableCaption, TableContainer, Tbody, Td,
    Text, Tfoot, Th, Thead, Tooltip, Tr,
    VStack
} from "@chakra-ui/react";
import {CopyIcon, InfoIcon} from "@chakra-ui/icons";
import React, {useState} from "react";
import InviteStatus from "./components/InviteStatus";
import {getEvents, getOpportunites, getOppsInvited, getUnstartedEvents, postOppInvite} from "./scripts/InviteData";
import {getIDFromCookie} from "../../auth/TokenTools";
import {getHostIDFromCog} from "../../../common/UserAPI/UserCalls";
import {getAddresses} from "./scripts/FormData";
import {tidyDateTime} from "./scripts/Helper";
import cardBG from "../../../assets/cardBG.jpg";
import {sendBusinessInviteEmail} from "../../../common/EventAPI/EventCalls";
import {guardthispage} from "../../auth/PageTools";

export default function InviteO() {

    const [eventsLoaded, setEventsLoaded] = useState(false);
    const [eventsList, setEventsList] = useState([]);
    const [oppList, setOppList] = useState([]);
    const [invitedList, setInvitedList] = useState([]);
    const [eventSelected, setEventSelected] = useState(0);
    const [hostID, setHostID] = useState(0)

    if(!eventsLoaded){
        guardthispage("Host");
        getIDFromCookie().then((response) => {
            getHostIDFromCog(response).then((response2) => {
                const eventListRaw = getUnstartedEvents(response2);
                eventListRaw.then(result=>{
                    setEventsList(result.data);
                    //console.log(result.data);
                })
                setHostID(response2);
            });
        });

        setEventsLoaded(true);
    }

    function copyToClipboard(text) {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                alert("Email copied to clipboard:", text);
            })
            .catch((error) => {
                alert("Failed to copy email address:", error);
            });
    }

    function getSubstringIfLongerThan(text) {
        if (text.length > 15) {
            return text.substring(0, 10)+"...";
        } else {
            return text;
        }
    }

    function punchEvent(eventId){
        setEventSelected(eventId);
        const oppListRaw = getOpportunites(eventId);
        oppListRaw.then(result=>{
            setOppList(result.data);
            //console.log(result.data);
        })

        const invitedListRaw =getOppsInvited(eventId);
        invitedListRaw.then(result=>{
            setInvitedList(result.data);
            //console.log(result.data);
        })
    }

    function punchInvite(oppId, email, name){
        postOppInvite(eventSelected, oppId).then(result=>{

            const success = sendBusinessInviteEmail(name, email);

            const oppListRaw = getOpportunites(eventSelected);
            oppListRaw.then(result=>{
                setOppList(result.data);
                //console.log(result.data);
            })

            const invitedListRaw =getOppsInvited(eventSelected);
            invitedListRaw.then(result=>{
                setInvitedList(result.data);
                //console.log(result.data);
            })
        })
    }


    return (
        <Box padding={'10px'} minHeight={'450px'} textColor={'darkslategray'}>
            <Heading>Invite Opportunities</Heading>

            <Text marginY={'20px'}>Once you have created an event you can invite businesses/founders to pitch their opportunities. Select the Event below and then invite the available opportunities for your area.</Text>
            <Divider></Divider>
            <Container width={'1080px'} marginTop={'20px'} maxW='container.xl' textAlign={"center"}>
                <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(5, 1fr)' gap={4}>
                    <Box colSpan={2} fontSize={'small'} >
                        <VStack divider={<StackDivider borderColor='gray.200' />} spacing={4} align='stretch' >
                            <Box h='15px'>
                                <Heading size={'small'}>Upcoming Events</Heading></Box>
                            {eventsList.map(
                                ((event, index)=>(
                                    <Button marginTop={'6px'} variant='solid' size='md' colorScheme='blue' onClick={() => {punchEvent(event.id)}}>{event.name}</Button>
                                )
                            ))}

                        </VStack>
                    </Box>

                    <Box colSpan={3} fontSize={'medium'} >
                        <TableContainer minW={'800px'} maxWidth={'800px'}>
                            <Table variant='simple' overflowX={"hidden"}>
                                <Thead>
                                    <Tr>
                                        <Th>Company</Th>
                                        <Th>Opp</Th>
                                        <Th>Needs</Th>
                                        <Th>Desc</Th>
                                        <Th>Contact</Th>
                                        <Th>Status</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {/*List the invited opportunities*/}
                                    {invitedList.map(
                                            ((event, index)=>(
                                                    <Tr key={event.id}>
                                                        <Td>{getSubstringIfLongerThan(event.businessName)}</Td>
                                                        <Td>{getSubstringIfLongerThan(event.name)}</Td>
                                                        <Td>{event.typeName}</Td>
                                                        <Td textAlign="center" verticalAlign="middle"><Tooltip label={event.description} aria-label="A tooltip"><InfoIcon /></Tooltip></Td>
                                                        <Td textAlign="center" verticalAlign="middle">
                                                            <IconButton
                                                                icon={<CopyIcon />}
                                                                aria-label="Copy to Clipboard"
                                                                size="lg"
                                                                onClick={() => copyToClipboard(event.email)}
                                                                variant="ghost" // Optional, change button style
                                                                isRound={true} // Optional, makes the button round
                                                            />

                                                        </Td>
                                                        <Td><InviteStatus response={event.response} responseRecieved={event.responseRecieved}/></Td>
                                                    </Tr>
                                                )
                                            ))}
                                    {/*List the open opportunities*/}
                                    {oppList.map(
                                            ((event, index)=>(
                                                    <Tr key={event.id}>
                                                        <Td>{getSubstringIfLongerThan(event.businessName)}</Td>
                                                        <Td>{getSubstringIfLongerThan(event.name)}</Td>
                                                        <Td>{event.typeName}</Td>
                                                        <Td textAlign="center" verticalAlign="middle"><Tooltip label={event.description} aria-label="A tooltip"><InfoIcon /></Tooltip></Td>
                                                        <Td textAlign="center" verticalAlign="middle">
                                                            <IconButton
                                                                icon={<CopyIcon />}
                                                                aria-label="Copy to Clipboard"
                                                                size="lg"
                                                                onClick={() => copyToClipboard(event.email)}
                                                                variant="ghost" // Optional, change button style
                                                                isRound={true} // Optional, makes the button round
                                                            />

                                                        </Td>
                                                        <Td><Button marginTop={'6px'} variant='solid'colorScheme='green' onClick={() => {punchInvite(event.id, event.email, event.businessName)}}>Invite</Button></Td>
                                                    </Tr>
                                                )
                                            ))}

                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>


                </Grid>





            </Container>
        </Box>
    );
}
