//checkin/4D2053D6-34E0-4AB5-A412-8B3F210ECDB9
import {getAccessTokenFromCookie} from "../../routes/auth/TokenTools";
import axios from "axios";

const event_api_url = process.env.REACT_APP_EVENT_API

export async function getHostAddresses(hostID){

    var AccessToken = getAccessTokenFromCookie();
    const URL = event_api_url + "addresses/" + hostID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}

export async function getEvent(eventID){

    var AccessToken = getAccessTokenFromCookie();
    const URL = event_api_url + "event/" + eventID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}

export async function updateEvent(dataObject){

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "event";

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.put(URL, dataObject));

}


export async function getEventSummary(eventID){

    var AccessToken = getAccessTokenFromCookie();
    const URL = event_api_url + "checkin/" + eventID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}

export async function getVoteStatus(dataObject){

    var AccessToken = getAccessTokenFromCookie();
    const URL = event_api_url + "eventvotestatus/";

    const client = axios.create({
        baseURL:URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(URL, dataObject));

}

export async function getEventCandidates(eventCode){

    var AccessToken = getAccessTokenFromCookie();
    const URL = event_api_url + "eventcandidates/" + eventCode;

    const client = axios.create({
        baseURL:URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.get(URL));

}

export async function submitvotes(eventCode, userId, selectedItems){

    const eventVotes = {};
    eventVotes.eventCode=eventCode;
    eventVotes.userId=userId;
    eventVotes.opps=selectedItems;

    var AccessToken = getAccessTokenFromCookie();
    const URL = event_api_url + "eventvote/";

    const client = axios.create({
        baseURL:URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(URL, eventVotes));

}

export async function lockInEvent(eventID){

    var AccessToken = getAccessTokenFromCookie();
    const URL = event_api_url + "lockIn/" + eventID;
    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));
}

export async function sendTechInviteEmail(name, email){

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EMAIL_API + "send-tech-invite";

    const dataObject = {
        "toName": name,
        "toAddress": email
    };

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(URL, dataObject));

}


export async function sendBusinessInviteEmail(name, email){

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EMAIL_API + "send-biz-invite";

    const dataObject = {
        "toName": name,
        "toAddress": email
    };

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(URL, dataObject));

}

export async function inviteTechs(inviteeObject){

    var AccessToken = getAccessTokenFromCookie();
    var counter = 0;
    const URL = process.env.REACT_APP_EMAIL_API + "send-biz-invite";

    for (let i = 0; i < inviteeObject.length; i++) {
        sendTechInviteEmail(inviteeObject[i].name, inviteeObject[i].email);
        counter++;
    }

    return (counter);
}

export async function openVoting(eventID){
    var AccessToken = getAccessTokenFromCookie();
    const URL = event_api_url + "open/" + eventID;
    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));
}

export async function closeVoting(eventID){
    var AccessToken = getAccessTokenFromCookie();
    const URL = event_api_url + "close/" + eventID;
    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));
}

export async function coreUserCheckIn(coreUserID, eventID){

    var AccessToken = getAccessTokenFromCookie();
    const URL = event_api_url + "eventcheckin/";

    var dataObject = {};
    dataObject["CoreUserID"] = coreUserID;
    dataObject["EventID"] = eventID;

    const client = axios.create({
        baseURL:URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(URL, dataObject));

}

// New code for batching emails to users

export async function sendTechInviteBatch(inviteeObject){

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EMAIL_API + "send-tech-invite-batch";

    const emailsObject = {};
    emailsObject["emails"] = [];

    for (let i = 0; i < inviteeObject.length; i++) {

        var dataObject = {};
        dataObject["toAddress"] = inviteeObject[i].email;
        dataObject["toName"] = inviteeObject[i].name;

        emailsObject.emails.push(dataObject);
    }

    console.log(JSON.stringify(emailsObject));

     const client = axios.create({
         baseURL: URL,
         headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
     })

    return (await client.post(URL, emailsObject));

    //return ("done");

}