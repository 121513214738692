import {
    Box,
    Button, Checkbox,
    Container,
    FormControl, Grid, GridItem, Heading,
    Input,
    InputGroup,
    InputLeftAddon, Text,
    useToast
} from "@chakra-ui/react";
import {getAddresses} from '../scripts/FormData'
import {postOpp} from "../scripts/FormData";
import {getHostIDFromCog} from "../../../../common/UserAPI/UserCalls";
import {getIDFromCookie} from "../../../auth/TokenTools";
import {useState} from "react";
import {useFormik} from 'formik';
import {json} from "react-router-dom";
import {guardthispage} from "../../../auth/PageTools";

export default function CreateEventForm() {

    // State Variables
    const [firstLoad, setFirstLoad] = useState(false);
    const [addressData, setAddressData] = useState("");
    const [date, setDate] = useState(new Date());
    const [hostID, setHostID] = useState(0);
    const [submitted, setSubmitted] = useState(false);

    const toast = useToast()

    // Form filler functions
    if(!firstLoad) {

        guardthispage("Host");
        getIDFromCookie().then((response) => {
            getHostIDFromCog(response).then((response2) => {
                setHostID(response2);
                const AddressData = getAddresses(response2);
                AddressData.then((value) => {
                    setAddressData(value.data);
                    setFirstLoad(true);
                })
            });
        });


    }

    const validate = values => {
        const errors = {};
        // name errors
        if (!values.name) { errors.name = 'Please enter the name of your event'; }
        // theme errors
        if (!values.theme) { errors.theme = 'Please enter the theme of your event'; }
        // address errors
        if (values.address=="") { errors.address = 'Please select the address'; }
        // address errors
        if (values.max=="") { errors.max = 'Please select the max capacity of attendees'; }
        // description errors
        if (!values.notes) { errors.notes = 'Please enter a description for attendees of your event'; } else if (values.notes.length < 30) { errors.notes = 'Please ensure the description is over 30 characters long'; }
        // date errors
        if (values.datey=="") { errors.datey = 'Please select an event date in the future'; }
        // date errors
        if (values.timey=="") { errors.timey = 'Please select an event time in the future'; }


        return errors;
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            theme:'',
            notes:'',
            datey: '',
            timey: '',
            max:'',
            curated:'',
            active:'',
            address: '',
            countrywide: false
        },
        validate,
        onSubmit: values => {
            const today = new Date().toISOString();
            let fullData = {
                Name:values.name,
                Theme:values.theme,
                Notes:values.notes,
                When: new Date(`${values.datey.toString()}T${values.timey.toString()}:00`).toISOString(),
                AddressID:Math.floor(values.address),
                InviteMax:Math.floor(values.max),
                HostID:hostID,
                Curated:true,
                Active:true,
                Countrywide:values.countrywide
            }
            const dataObject = (JSON.stringify(values, null, 2));

            // Post the data object
            postOpp(JSON.stringify(fullData)).then(function (response) {
                //console.log(response);
                toast({
                    title: 'And we are off!',
                    description: "Your Event has been posted",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            }).catch(function (error) {
                    toast({
                        title: 'Issues detected!',
                        description: "Nope sorry - something ain't working as expected",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                });

        },
    });






    return (
        <Box padding={'10px'}  textColor={'darkslategray'}>


            <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(5, 1fr)' gap={10}>
                <GridItem colSpan={3} >
                    <Heading>Create a new event</Heading>
                    <Text marginY={'20px'}>Creating an event is the first step to connecting technologists and businesses/founders who need their skills. Using the form below enter details of your event and once created you will be able to invite businesses/founders to pitch</Text>
                    <FormControl textColor={'darkslategray'}>
            <form onSubmit={formik.handleSubmit}>
                <InputGroup marginY={'5px'}>
                    <InputLeftAddon width={'120px'}>Event Name</InputLeftAddon>
                    <Input id='name' name='name' type='name' onChange={formik.handleChange} value={formik.values.name}/>
                </InputGroup>

                <InputGroup marginY={'5px'}>
                    <InputLeftAddon width={'120px'}>Theme</InputLeftAddon>
                    <Input id='theme' name='theme' type='theme' onChange={formik.handleChange} value={formik.values.theme}/>
                </InputGroup>

                <InputGroup marginY={'5px'}>
                    <InputLeftAddon width={'120px'}>Capacity</InputLeftAddon>
                    <Input as="select" name='max' onChange={formik.handleChange} value={formik.values.address}>
                        <option key="" value="">How many attendees can you hold?</option>
                        <option key="10" value="10">10</option>
                        <option key="20" value="20">20</option>
                        <option key="50" value="50">50</option>
                        <option key="100" value="100">100</option>
                        <option key="200" value="200">200</option>
                    </Input>
                </InputGroup>


                <InputGroup marginY={'5px'}>
                    <InputLeftAddon width={'120px'}>Date</InputLeftAddon>
                    <Input type="date" name="datey"  onChange={formik.handleChange} value={formik.values.datey}/>
                </InputGroup>


                <InputGroup marginY={'5px'}>
                    <InputLeftAddon width={'120px'}>Time</InputLeftAddon>
                    <Input type="time" name="timey"  onChange={formik.handleChange} value={formik.values.timey}/>
                </InputGroup>


                <InputGroup marginY={'5px'}>
                    <InputLeftAddon width={'120px'}>Address</InputLeftAddon>
                    <Input as="select" name='address' onChange={formik.handleChange} value={formik.values.address}>
                        <option key="" value="">Please Select</option>
                        {addressData.length > 0 && addressData.map((option) => <option key={option.id}
                                                                                 value={option.id}>{option.name}</option>)}
                    </Input>
                </InputGroup>


                <InputGroup marginY={'5px'}>
                    <InputLeftAddon width={'120px'}>Description</InputLeftAddon>
                    <Input as="textarea" id='notes' name='notes' placeholder='Enter a description of your event here' maxLength={'400'}  onChange={formik.handleChange} value={formik.values.notes}/>
                </InputGroup>
                <InputGroup marginY={'5px'}>
                    <InputLeftAddon width={'120px'}>Scope</InputLeftAddon>
                    <Checkbox id='countrywide' name='countrywide' onChange={formik.handleChange} value={formik.values.countrywide} paddingLeft={'20px'}>Extend countrywide?</Checkbox>
                </InputGroup>

                <Button mt={4} colorScheme='teal' type='submit'>Submit</Button>
            </form>
        </FormControl>
                </GridItem>
                <GridItem colSpan={2} borderLeft={"solid"} paddingLeft={'20px'}>
                    <Heading>Validation</Heading>
                    <Text marginY={'20px'}>As you complete the form helpful text will appear here to help keep you on track.</Text>
                    {formik.errors.name ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.name}</Container> : null}
                    {formik.errors.theme ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.theme}</Container> : null}
                    {formik.errors.max ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.max}</Container> : null}
                    {formik.errors.datey ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.datey}</Container> : null}
                    {formik.errors.timey ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.timey}</Container> : null}
                    {formik.errors.address ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.address}</Container> : null}
                    {formik.errors.notes ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.notes}</Container> : null}
                </GridItem>
            </Grid>
        </Box>
    );
}
