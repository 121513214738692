import {Box, Container, Flex} from "@chakra-ui/react";
import React from "react";

export function Menubar(){
    return(
        <Box height={'220px'} bgColor={'white'}>
        <Box height={'220px'} >
            <Container maxWidth={'1200px'} centerContent >
                <Box bgColor={'white'} display="flex" justifyContent={'center'}  alignItems={'center'} width={'800px'} paddingTop={'30px'}><img src="/Foundry%20Fuel_multi.jpg" width={'400px'} alt={'Foundry Fuel Logo'}/></Box>
{/*                <Box width={'800px'} paddingRight='4' marginBottom={'20px'} bg='transparent' color='black'>
                    <Flex color='black' fontSize={'13pt'}  className={'montserrat-link'}>
                        <Box bg='transparent' paddingTop={'4px'} paddingRight={'25px'}><a href={'/manifesto'}>MANIFESTO</a></Box>
                        <Box bg='transparent' paddingTop={'4px'} paddingRight={'25px'}><a href={'/techno'}>TECHNOLOGISTS</a></Box>
                        <Box bg='transparent' paddingTop={'4px'} paddingRight={'25px'}><a href={'/curated'}>CURATED EVENTS</a></Box>
                        <Box bg='transparent' paddingTop={'4px'} paddingRight={'25px'}><a href={'/accountable'}>ABOUT US</a></Box>
                    </Flex>
                </Box>*/}

            </Container>
        </Box>
        </Box>
    );

}