import {Box, Container, Flex, Grid, GridItem, Link} from "@chakra-ui/react";
import React from "react";
import {IoHomeOutline} from "react-icons/io5";
import {FaRegUserCircle} from "react-icons/fa";
import CookieConsent from "react-cookie-consent";

export function Footer(){
    return(
        <Box h='42px' bg='transparent' color={'white'}>
            <Container maxWidth={'1024px'} bg='transparent' centerContent>
                <Grid templateColumns='repeat(9, 1fr)' width={'100%'} background={'transparent'} gap={0}>
                    <GridItem w='100%' h='12' colSpan={3} paddingTop={'10px'} bg='transparent'>Copyright 2025 - FoundryFuel Ltd - v0.12c</GridItem>
                    <GridItem w='100%' h='12' colSpan={2} bg='transparent'>&nbsp;</GridItem>
                    <GridItem w='100%' h='12' colSpan={1} paddingTop={'10px'} bg='transparent'><a href={'/licenses'}>Licenses</a></GridItem>
                    <GridItem w='100%' h='12' colSpan={1} paddingTop={'10px'} bg='transparent'><a href={'/contact'}>Contact Us</a></GridItem>
                    <GridItem w='100%' h='12' colSpan={1} paddingTop={'10px'} bg='transparent'>
                        <Link href='/TANDC.pdf' isExternal id="tText" rel="noopener noreferrer" target="_blank">Terms&C</Link>
                    </GridItem>
                    <GridItem w='100%' h='12' colSpan={1} paddingTop={'10px'} bg='transparent'>
                        <Link href='/PP.pdf'  isExternal id="pText" target="_blank" rel="noopener noreferrer">Privacy</Link>
                    </GridItem>
                </Grid>
            </Container>
            <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>

        </Box>
    );

}