import {Box, Center, Container, Grid, GridItem} from "@chakra-ui/react";
import { isMobile } from 'react-device-detect';

import Host from './navigation/Host';
import Techno from "./navigation/Techno";
import Business from "./navigation/Business";
import Userbar from "./dashboard/modules/userbar";
import {useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import { IconButton } from '@chakra-ui/react'
import { MdHome } from 'react-icons/md'
import {getIDFromCookie, isTokenExpired, doRefresh} from "./auth/TokenTools";
import {writeUserTypeCookie, readUserTypeCookie} from "./auth/PageTools";
import {
    getBusinessApproved,
    getBusinessIDFromCog,
    getHostApproved,
    getHostIDFromCog,
    getUserTypeNameFromCog,
    isLoggedIn
} from "../common/UserAPI/UserCalls";

export default function Dashboard() {

    const navigate = useNavigate();


    if(!isLoggedIn()) {
        try{
            if(isTokenExpired()){ doRefresh(); if(isTokenExpired()){ navigate("/"); }}
        }
        catch(error){
            navigate("/");
        }
    }

    // User States
    const [userType, setUserType] = useState("")
    const [cogID, setCogID] = useState("")

    if(userType.length<=0){
        if(!readUserTypeCookie()){
            getIDFromCookie().then((response) => {
                getUserTypeNameFromCog(response).then((response2) => {
                    writeUserTypeCookie(response2);
                    setUserType(response2);
                    if(window.location.pathname=='/dashboard/' || window.location.pathname=='/dashboard'){
                        switch(response2) {
                            case "Host":

                                getHostIDFromCog(response).then((response3) => {
                                    getHostApproved(response3).then((response4) => {
                                        if(!response4){
                                            window.location.replace("/notyet");
                                        }
                                        else{
                                            navigate("/dashboard/host");
                                        }
                                    })
                                });

                                break;
                            case "Business":

                                getBusinessIDFromCog(response).then((response3) => {
                                    getBusinessApproved(response3).then((response4) => {
                                        if(!response4){
                                            window.location.replace("/notyet");
                                        }
                                        else{
                                            navigate("/dashboard/biz");
                                        }
                                    })
                                });


                                break;
                            default:
                                navigate("/dashboard/tech");
                        }
                    }
                });
            })
        }
        else{
            setUserType(readUserTypeCookie());
        }
    }


    if(window.location.pathname=='/dashboard/' || window.location.pathname=='/dashboard'){
        getIDFromCookie().then((response) => {
            getUserTypeNameFromCog(response).then((response2) => {
                switch(response2) {
                    case "Host":

                        getHostIDFromCog(response).then((response3) => {
                            getHostApproved(response3).then((response4) => {
                                if(!response4){
                                    window.location.replace("/notyet");
                                }
                                else{
                                    navigate("/dashboard/host");
                                }
                            })
                        });

                        break;
                    case "Business":

                        getBusinessIDFromCog(response).then((response3) => {
                            getBusinessApproved(response3).then((response4) => {
                                if(!response4){
                                    window.location.replace("/notyet");
                                }
                                else{
                                    navigate("/dashboard/biz");
                                }
                            })
                        });


                        break;
                    default:
                        navigate("/dashboard/tech");
                }
            });
        });


    }





    return (
        <Center bg='transparent' color='white' minHeight={'400px'} overflowY={'hidden'} marginTop={'20px'}>
            <Box width={'1420px'} minHeight={'400px'} overflowY={'hidden'} borderWidth='2px' borderColor={'silver'} backgroundColor={'#EEEEEE'} borderRadius='md' padding={'10px'}>
                <Grid overflow={'hidden'} templateRows='repeat(1, 1fr)' templateColumns='repeat(10, 1fr)' gap={0} >
                    <GridItem rowSpan={1} colSpan={2} bg='transparent' marginRight={'10px'} >
                        <Box height={'100%'} width={'100%'} paddingX={'8px'} backgroundColor={'white'} borderWidth='none' borderRadius='lg'>
                            <Container paddingY={'20px'}> <img src="/ff.jpg" alt={'Foundry Fuel'}/></Container>
                            {userType=="Host" && <Host></Host>}
                            {userType=="Technologist" && <Techno></Techno>}
                            {userType=="Business" && <Business></Business>}
                        </Box>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={8} height={'auto'}>
                        <Box height={'50px'} width={'100%'} backgroundColor={'white'} borderWidth='none' borderRadius='lg' marginBottom={'10px'} textAlign={'right'}>
                            <Userbar userType={userType}/>
                        </Box>
                        <Box height={'auto'} width={'100%'} backgroundColor={'white'} borderWidth='none' borderRadius='lg'>
                            <Outlet />
                        </Box>
                    </GridItem>
                </Grid>
            </Box>
        </Center>
    );
}
