import React, {useEffect, useRef, useState} from "react";
import {countTechs, getEvents, getPastEvents} from "./scripts/InviteData";
import {
    background,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Container,
    Divider,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Stack,
    StackDivider,
    Text,
    useDisclosure,
    VStack,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import {getIDFromCookie} from "../../auth/TokenTools";
import {getHostIDFromCog} from "../../../common/UserAPI/UserCalls";
import {
    lockInEvent,
    openVoting,
    closeVoting,
    inviteTechs,
    sendTechInviteBatch
} from "../../../common/EventAPI/EventCalls";
import {tidyDateTime} from "./scripts/Helper";
import EditEventForm from "./forms/EditEventForm";
import HowManyTechs from "./components/HowManyTechs";
import EventStats from "./components/EventStats";
import {CalendarIcon, ExternalLinkIcon, LockIcon} from '@chakra-ui/icons'
import {AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, AlertDialogCloseButton,} from '@chakra-ui/react'
import {guardthispage} from "../../auth/PageTools";



export default function ManageE() {
    const [eventsLoaded, setEventsLoaded] = useState(false);
    const [eventsList, setEventsList] = useState([]);
    const [pastEventList, setPastEventList] = useState([])
    const [oppList, setOppList] = useState([]);
    const [invitedList, setInvitedList] = useState([]);
    const [eventSelected, setEventSelected] = useState(0);
    const [bizID, setBizID] = useState();
    const [selectedLockinID, setSelectedLockinID] = useState(0);
    const [fireMe, setFireMe] = useState(false);

    // Event Editing variables
    const [editEventID, setEditEventID] = useState(0);

    function editEvent(eventID){
        setEditEventID(eventID);
    }


    if(!eventsLoaded){
        guardthispage("Host");
        getIDFromCookie().then((response) => {
            getHostIDFromCog(response).then((response2) => {
                setBizID(response2);
                const eventListRaw = getEvents(response2);
                const pastEventListRaw = getPastEvents(response2);
                eventListRaw.then(result=>{
                    setEventsList(result.data);
                    //console.log(result.data);
                })
                pastEventListRaw.then(result=>{
                    setPastEventList(result.data);
                    //console.log(result.data);
                })
                setEventsLoaded(true);
            });
        });
    }

    function punchEvent(eventId){
        lockInEvent(eventId).then((response) => {
            JSON.stringify(response.data);
            //var allInvited = inviteTechs(response.data);
            var allInvited = sendTechInviteBatch(response.data);
            allInvited.then(result=>{
                setEventsLoaded(false);
            })
        });
    }

    function openVotingNow(eventId){
        openVoting(eventId);
        setEventsLoaded(false);
    }

    function closeVotingNow(eventId){
        closeVoting(eventId);
        setEventsLoaded(false);
    }

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();

    const handleConfirm = () => {
        punchEvent(selectedLockinID);
        onClose(); // Close the dialog after action
    };


    if(editEventID==0) {
        return (

            <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(7, 1fr)' gap={5} paddingLeft={'10px'}>
                <GridItem colSpan={5} >
                    <Box padding={'10px'}  textColor={'darkslategray'}>


                <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                WARNING : Confirm LockIn
                            </AlertDialogHeader>

                            <AlertDialogBody paddingLeft={'10'} paddingRight={'5'}>
                                <Heading as={"h2"} size={'lg'} color={'darkred'}><strong>PLEASE READ!</strong></Heading><br/>
                                <Text as={'em'} color='darkred'>Are you sure you want to lock in this event?</Text><br/><br/>
                                <Box marginLeft={'20px'} marginBottom={'10px'}>
                                    <ul>
                                        <li>Invites will be sent to all the potential technologists</li>
                                        <li>Any pending invites to presenters will be auto declined</li>
                                        <li><strong>There is no way to go back</strong></li>
                                    </ul>
                                </Box>
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button colorScheme="red" onClick={handleConfirm} ml={3}>
                                    <LockIcon h={4}/>&nbsp;Lock In
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>




                <Heading marginY={'20px'}>Upcoming Events</Heading>
                <Text marginY={'20px'}>So you have created some events, invited your founders/businesses and now you are ready to lock it in and send out the invites? Below is a list of your upcoming events. Get busy!</Text>
                <Box paddingY={'20px'}>
                    <Wrap justify='left' spacing={'20px'}>
                        {eventsList.map(
                            ((event, index)=>(
                                    <WrapItem  key={event.id}>
                                        <Card width={'350px'} key={event.id} minHeight={'365px'} backgroundColor={'white'} borderWidth={'2px'} borderColor={'gray.200'}>
                                            <CardBody padding={'0px'} margin={'0px'}>
                                                <Stack mt='6' spacing='3'>
                                                    <Box backgroundColor={'#7CDAFF'} textColor={'slategray'} paddingX={'10px'} paddingY={'5px'}>
                                                        <Heading size='lg'>{event.name}</Heading>
                                                    </Box>
                                                    <Box paddingX={'20px'} paddingY={'5px'}>
                                                        <Heading size='md'  as='i'>Theme : {event.theme}</Heading>
                                                        <Text size='md' height={'30px'} overflow={'hidden'}>{event.notes}</Text>
                                                        <Divider marginY={'5px'} />
                                                        <Grid templateColumns='repeat(6, 1fr)' gap={0}>
                                                            {!event.locked && <GridItem w='100%' colSpan={6} color={'royalblue'}><CalendarIcon h={3} color={"royalblue"}/><strong> Phase No.1</strong></GridItem>}
                                                            {event.locked && !event.votingOpen && <GridItem w='100%' colSpan={6} color={'darkgoldenrod'}><CalendarIcon h={3} color={"darkgoldenrod"}/> Phase No.2</GridItem>}
                                                            {event.locked && event.votingOpen && !event.votingClosed && <GridItem w='100%' colSpan={6} color={'darkorange'}><CalendarIcon h={3} color={"darkorange"}/> Phase No.3</GridItem>}
                                                            {event.locked && event.votingOpen && event.votingClosed && <GridItem w='100%' colSpan={6} color={'darkred'}><CalendarIcon h={3} color={"darkred"}/> Phase No.4</GridItem>}
                                                            <GridItem w='100%' colSpan={3}><CalendarIcon h={3} color={"darkslategrey"}/> Date</GridItem>
                                                            <GridItem w='100%' colSpan={3} textAlign={'right'}>{tidyDateTime(event.when)}</GridItem>
                                                        </Grid>
                                                        <EventStats id={event.id}></EventStats>
                                                        {!event.locked && <HowManyTechs id={event.id}></HowManyTechs>}
                                                    </Box>
                                                </Stack>
                                            </CardBody>
                                            <Divider />
                                            <CardFooter>
                                                <ButtonGroup spacing='2'>
                                                    {!event.locked && <Button variant='solid' marginTop={'0px'} colorScheme='yellow' onClick={() => {editEvent(event.id)}}>Edit Event</Button>}
                                                    {!event.locked && <Button variant='solid' marginTop={'0px'} colorScheme='blue' onClick={() => { setSelectedLockinID(event.id); onOpen();}}><LockIcon h={4}/> &nbsp;Lock In!&nbsp;</Button>}
                                                    {event.locked && !event.votingOpen && <Button variant='outline' marginTop={'0px'} colorScheme='green' onClick={() => window.open('../../event?code='+event.code, '_blank')}>CheckIn Page&nbsp;<ExternalLinkIcon h={4}/></Button>}
                                                    {event.locked && !event.votingOpen && <Button variant='solid' marginTop={'0px'} colorScheme='green' onClick={() => {openVotingNow(event.id)}}>Open Voting</Button>}
                                                    {event.locked && event.votingOpen && !event.votingClosed && <Button variant='outline' marginTop={'0px'} colorScheme='red' onClick={() => {closeVotingNow(event.id)}}>Close Voting</Button>}
                                                    {event.locked && event.votingOpen && event.votingClosed && <Button variant='outline' marginTop={'0px'} colorScheme='purple' isDisabled={'true'}>Event Completed</Button>}
                                                </ButtonGroup>
                                            </CardFooter>
                                        </Card>
                                    </WrapItem>
                                )
                            ))}
                    </Wrap>
                </Box>

                <Divider></Divider>
                <Heading marginY={'20px'}>Past Events</Heading>
                <Box paddingY={'20px'}>
                    <Wrap justify='left' spacing={'20px'}>
                        {pastEventList.map(
                            ((event, index)=>(
                                    <WrapItem  key={event.id}>
                                        <Card width={'350px'} key={event.id} backgroundColor={'white'} borderWidth={'2px'} borderColor={'gray.200'}>
                                            <CardBody padding={'0px'} margin={'0px'}>
                                                <Stack mt='6' spacing='3'>
                                                    <Box backgroundColor={'#FF7E79'} textColor={'white'} paddingX={'10px'} paddingY={'5px'}>
                                                        <Heading size='lg'>{event.eventName}</Heading>
                                                    </Box>
                                                    <Box paddingX={'20px'} paddingY={'5px'}>
                                                        <Heading size='md'>{event.address}</Heading>
                                                        <Heading size='sm'>{event.eventDate}</Heading>
                                                        <Text size='md'>Technologists in attendance : {event.eventCheckIns}</Text>
                                                        <Text size='md'>Votes Cast : {event.eventVoteCount}</Text>
                                                    </Box>
                                                </Stack>
                                            </CardBody>
                                        </Card>
                                    </WrapItem>
                                )
                            ))}
                    </Wrap>
                </Box>

            </Box>
                </GridItem>
                <GridItem colSpan={2} borderLeft={"solid"} paddingX={'10px'} borderLeftColor={'slategrey'}
                          textColor={'darkslategray'} textAlign={'center'}>
                    <Heading marginTop={'20px'}>Phases of an event</Heading>
                    <Text marginBottom={'10px'}>Events follow set phases which you need to follow to get the most from
                        them.</Text>
                    <hr/>
                    <Heading as={'h3'} size={'md'} color={'darkslategray'} marginTop={'10px'} textColor={'royalblue'}>Phase 1 :
                        Edit/LockIn</Heading>
                    <Text marginY={'10px'}>Edit the event and when ready lock it in. At this point all invites are sent
                        out to technologists.</Text>
                    <hr/>
                    <Heading as={'h3'} size={'md'} color={'darkslategray'} marginTop={'10px'} textColor={'darkgoldenrod'}>Phase 2 : Start The
                        Event</Heading>
                    <Text marginY={'10px'}>You can now get the checkin page for your event and, when ready, open the
                        voting process.</Text>
                    <hr/>
                    <Heading as={'h3'} size={'md'} color={'darkslategray'} marginTop={'10px'} textColor={'darkorange'}>Phase 3 : Close The
                        Voting</Heading>
                    <Text marginY={'10px'}>You can now close the voting so that no-one else can check in and start the
                        voting process</Text>
                    <hr/>
                    <Heading as={'h3'} size={'md'} color={'darkslategray'} marginTop={'10px'} textColor={'darkred'}>Phase 4 : Close The
                        Event</Heading>
                    <Text marginY={'10px'}>You can now close the event so no-one can check in and all votes and connections are made.</Text>
                </GridItem>
            </Grid>


        );
    } else {
        return (
            <EditEventForm ID={editEventID}></EditEventForm>
        );
    }


}
